import { BillingMethod, PaymentMethod, RegularizationFrequency } from './constants';

export interface Bundle {
	name: string;
	code: string;
	i18n?: I18n;
	description?: I18n;
	commercialLinks?: I18n;
	price: string;
	market: string;
	active: boolean;
	products: Product[];
	promotions: Promotion[];
	contractDuration?: ContractDurationItem[];
	subscriptionRules?: SubscriptionRules;
}

export interface SubscriptionRules {
	billingFrequencies: string[];
	billingSendingMethods: string[];
	paymentMethods: string[];
	regularizationFrequenciesElectricity: RegularizationFrequency[];
	regularizationFrequenciesGas: RegularizationFrequency[];
}

export enum BillingSendingMethod {
	POST = 'POST',
	PAPER = 'PAPER',
	EMAIL = 'EMAIL',
	ALL = 'ALL'
}

export interface ContractDurationItem {
	region: string;
	max: number;
	min: number;
	indeterminate: boolean;
}

export interface Product {
	name: string;
	code: string;
	category: string;
	subCategory: string;
	priceType: string; // TARIFF_GRID/PRICE_MATRIX/QUOTING_ENGINE
	tariffType: string; // FIX/VARIABLE
	i18n?: I18n;
}

export interface Promotion {
	code: string;
	description: I18n;
	default: boolean;
	applicationCriteria: ApplicationCriteria;
}

export interface EC2Promotion {
	name: string;
}
export interface Promotion {
	code: string;
	startDate: string;
	endDate: string;
}

export interface I18n {
	fr: string;
	nl: string;
	en: string;
}

export interface ApplicationCriteria {
	paymentMethod: PaymentMethod;
	billingFrequency: BillingMethod;
	billingSendingMethod: BillingSendingMethod;
	region: string;
}
export interface EligibilityRequest {
	products: EligibilityItem[];
	services: EligibilityItem[];
}

export interface EligibilityItem {
	code: string;
	name: string;
	option: string;
}
